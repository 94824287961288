import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getId, postAnalytics } from "../controllers/qrs";
import dynamics from "../database/dynamics.json";
import { getOSMobile } from "../utils/getOSMobile";
import { getBrowser } from "../utils/getBrowser";
import { getBrowserLanguage } from "../utils/getBrowserLanguage";
import { getGeolocation } from "../utils/getGeolocation";
import { isMobile } from "../utils/getIsMobile";
import { Texts } from "../previewpages/Texts";
import { Apps } from "../previewpages/Apps";
import { Event } from "../previewpages/Event";
import { Vcard } from "../previewpages/Vcard";
import { Social } from "../previewpages/Social";
import { LocalTaxi } from "@mui/icons-material";

export const Dispatcher = () => {
  const device = getOSMobile();
  const [qr, setQr] = useState(null);
  const location = useLocation();

  const handleGetQr = async (pathname) => {
    try {
      const res = await getId(pathname);
      setQr(res?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleStaticQr = (qr, device) => {
    switch (qr.type) {
      case "link":
        window.open(qr.info.data.url);
        break;
      case "email":
        window.open(
          `mailto:${qr.info.data.email}?subject=${qr.info.data.subject}&body=${qr.info.data.qr_call_text}`
        );
        break;
      case "call":
        window.open(`tel:${qr.info.data.phone_number}`);
        break;
      case "sms":
        window.open(
          `sms:${qr.info.data.phone_number}?body=${qr.info.data.message}`
        );
        break;
      case "whatsapp":
        window.open(
          `https://wa.me/${qr.info.data.whatsapp_phone_number}?text=${qr.info.data.whatsapp_message}`
        );
        break;
      case "app":
        window.open(qr.info.data[`${device.toLowerCase()}_url`]);
        break;
      case "event": {
        const url = encodeURI(
          `data:text/calendar;charset=utf8,${[
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "BEGIN:VEVENT",
            `SUMMARY:${qr.info.data.name}`,
            `LOCATION:${qr.info.data.location}`,
            `DESCRIPTION:${qr.info.data.event_description}`,
            `DTSTART:${qr.info.data.date_time_start}`,
            `DTEND:${qr.info.data.date_time_end}`,
            "END:VEVENT",
            "END:VCALENDAR",
          ].join("\n")}`
        );
        window.open(url);
        break;
      }
      case "wifi":
        window.open(
          `WIFI:T:${qr.info.data.network_type};S:${qr.info.data.ssid};P:${qr.info.data.password};;`
        );
        break;
      case "text":
        window.open(`/qrcodegen/api/qrcode/generate?data=${qr.info.data.text}`);
        break;
      default:
        break;
    }
  };

  // 1 - get qr
  useEffect(() => {
    handleGetQr(location.pathname.slice(1));
  }, [device, location.pathname]);

  // 2 - get config
  useEffect(() => {
    console.log(2);
    if (qr) {
      if (
        qr.behavior === "direct" &&
        !(qr.type === "app" && device === "unknown")
      ) {
        handleStaticQr(qr, device);
      }
      handleGetAnalytics(qr, device);
    }
  }, [qr, device]);

  const getUserGeolocation = async () => {
    try {
      const res = await getGeolocation();
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getGeo = async () => {
    try {
      const res = await getUserGeolocation();
      if (!res) return {};

      const loc = res?.loc;
      const latlong = loc.split(",");
      const latit = latlong[0];
      const longit = latlong[1];

      return {
        user_city: res?.city,
        user_country: res?.country,
        latitude: latit,
        longitude: longit,
        postal: res?.postal,
        region: res?.region,
        timezone: res?.timezone,
      };
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  // 3 - post analytics
  const handleGetAnalytics = async (qr, device) => {
    try {
      const geo = await getGeo();
      const analytics = {
        qr_id: qr.qrId,
        browser: getBrowser(),
        browser_language: getBrowserLanguage(),
        device: device,
        is_mobile: isMobile(),
        ...geo,
      };
      await postAnalytics({
        analytics,
      });
    } catch (err) {
      console.error(err);
    }
  };

  console.log("device", device);
  console.log("qr", qr);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {
        {
          text: <Texts qr={qr} />,
          app: <Apps qr={qr} device={device} />,
          event: <Event qr={qr} />,
          social: <Social qr={qr} />,
          vcard: <Vcard qr={qr} />,
        }[qr?.type]
      }
    </div>
  );
};
