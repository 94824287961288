import { Card } from "@mui/material";

export const Texts = ({ qr }) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: qr.pageBackgroundColor,
        height: "100%",
      }}
    >
      <Card
        style={{
          padding: 16,
          backgroundColor: qr.card_color,
          width: 247,
          marginTop: 35,
          overflow: "scroll",
          minHeight: 500,
        }}
      >
        <a
          style={{
            fontWeight: "bold",
            fontSize: 20,
            color: qr.text_color,
            padding: 75,
          }}
        >
          Your text:
        </a>
        <p />
        {qr.info.data.text}{" "}
      </Card>
    </div>
  );
};
