import axios from "axios";
// import { getCookie } from "../utils/getCookie";

// const API_BASE_URL = "http://localhost:3100";
const API_BASE_URL = "https://api.qrify.link";

// const token = getCookie("__session");

export const getId = async (code) => {
  // console.log("ID:", code);
  try {
    const response = await axios.get(`${API_BASE_URL}/qrs/code/${code}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
// export const postQrLink = async (link) => {
//   try {
//     console.log("LINK: ", link);
//     const response = await axios.post(`${API_BASE_URL}/qrs`, link, {
//       headers: {
//         Authorization: token,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// };
// const API_BASE_URL_APP = "http://localhost:8080";

// export const getIdApp = async () => {
//   try {
//     const response = await axios.get(`${API_BASE_URL_APP}/dashboard/qr`);
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const postAnalytics = async (link) => {
  console.log("Link: ", link.analytics);

  let configs = {
    qr_id: link.analytics.qr_id,
    browser: link.analytics.browser,
    browser_language: link.analytics.browser_language,
    device: link.analytics.device,
    is_mobile: link.analytics.is_mobile,
    latitude: link.analytics.latitude,
    longitude: link.analytics.longitude,
    postal: link.analytics.postal,
    region: link.analytics.region,
    timezone: link.analytics.timezone,
    user_city: link.analytics.user_city,
    user_country: link.analytics.user_country,
    user_date: link.analytics.user_date,

    user_time: link.analytics.user_time,
  };

  try {
    const response = await axios.post(`${API_BASE_URL}/qrs/analytics`, configs);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
