import { Card } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkIcon from "@mui/icons-material/Link";
import TelegramIcon from "@mui/icons-material/Telegram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import RedditIcon from "@mui/icons-material/Reddit";
export const Social = ({ qr }) => {
  const renderIcon = (type) => {
    switch (type) {
      case "facebook":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <FacebookIcon
              sx={{ marginLeft: 9, fontSize: 30, color: "#3b5998" }}
            />
            <div
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: -30,
                textDecoration: "double",
              }}
            >
              Facebook
            </div>
          </Card>
        );
      case "instagram":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <InstagramIcon
              sx={{ marginLeft: 9, fontSize: 30, color: "#C13584" }}
            />
            <div
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: -30,
              }}
            >
              Instagram
            </div>
          </Card>
        );
      case "linkedin":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <LinkedInIcon
              sx={{ marginLeft: 9, fontSize: 30, color: "#0e76a8" }}
            />
            <div
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: -30,
              }}
            >
              LinkedIn
            </div>
          </Card>
        );
      case "twitter":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <TwitterIcon
              sx={{ marginLeft: 9, fontSize: 30, color: "#00acee" }}
            />
            <div
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: -30,
              }}
            >
              Twitter
            </div>
          </Card>
        );
      case "youtube":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <YouTubeIcon
              sx={{ marginLeft: 9, fontSize: 30, color: "#c4032b" }}
            />
            <div
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: -30,
              }}
            >
              YouTube
            </div>
          </Card>
        );
      case "telegram":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <TelegramIcon
              sx={{ marginLeft: 9, fontSize: 30, color: "#0088cc" }}
            />
            <div
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: -30,
              }}
            >
              Telegram
            </div>
          </Card>
        );
      case "pinterest":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <PinterestIcon
              sx={{ marginLeft: 9, fontSize: 30, color: "#E60023" }}
            />
            <div
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: -30,
              }}
            >
              Pinterest
            </div>
          </Card>
        );
      case "reddit":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <RedditIcon
              sx={{ marginLeft: 9, fontSize: 30, color: "#FF4500" }}
            />
            <div
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: -30,
              }}
            >
              Reddit
            </div>
          </Card>
        );
      case "link":
        return (
          <Card
            fullWidth
            style={{
              padding: 3,
              marginBottom: 8,
              borderRadius: 30,
              height: 30,
            }}
            sx={{ border: "1px solid black" }}
          >
            {" "}
            <LinkIcon sx={{ marginLeft: 9, fontSize: 30, color: "#555555" }} />
            <div
              style={{
                fontSize: 20,

                marginTop: -30,
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              WebSite
            </div>
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: qr.pageBackgroundColor,
        height: "100%",
      }}
    >
      <Card
        style={{
          padding: 16,
          backgroundColor: qr.card_color,
          // width: 247,
          marginTop: 35,
          overflow: "scroll",
          minHeight: 500,
          minWidth: 300,
        }}
      >
        {!!qr.image_uploaded && (
          <img
            src={qr.image_uploaded}
            alt="logo"
            style={{
              marginLeft: "35%",
              borderRadius: "50%",
              border: `4px solid ${qr.pageBackgroundColor}`,
              width: 100,
              height: 100,
              zIndex: 1000,
            }}
          />
        )}
        <a
          style={{
            fontWeight: "bold",
            fontFamily: "unset",
            fontSize: 25,
            flex: 1,
            marginBottom: 10,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            color: qr.text_color,
            marginTop: 10,
          }}
        >
          Follow me on Social Media
        </a>
        <div>
          {qr.info.map((social) => (
            <div
              key={`preview_page_social_${social.id}`}
              style={{ height: 45 }}
            >
              {" "}
              {console.log("SOCIAL ID: ", social.social_type)}
              <a
                style={{ textDecoration: "none" }}
                href={social.url}
                target="_blank"
                rel="noreferrer"
              >
                {renderIcon(social.social_type)}
              </a>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};
