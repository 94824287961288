import { Card } from "@mui/material";
import { Apple, Android } from "@mui/icons-material";

export const Apps = ({ qr, device }) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: qr.pageBackgroundColor,
        height: "100%",
      }}
    >
      <Card
        elevation={10}
        style={{
          padding: 16,
          backgroundColor: qr.card_color,
          width: 260,
          overflow: "visible",
          minHeight: 480,
          maxHeight: "80vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {!!qr.image_uploaded && (
          <div
            style={{
              width: 120,
              height: 120,
              borderRadius: 24,
              boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
              zIndex: 1000,
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              position: "absolute",
              top: -60,
            }}
          >
            <img
              src={qr.image_uploaded}
              alt="logo"
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
            />
          </div>
        )}
        <div
          style={{
            width: "100%",
            flex: 1,
            marginTop: 60,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            {qr.info.data.page_title && (
              <div
                style={{
                  display: "flex",
                  fontWeight: "bold",
                  fontSize: 30,
                  alignItems: "center",
                  justifyContent: "center",
                  color: qr.text_color,
                }}
              >
                {qr.info.data.page_title}
              </div>
            )}
            {qr.info.data.page_description && (
              <div
                style={{
                  display: "flex",
                  fontWeight: "normal",
                  fontSize: 14,
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 32,
                  textAlign: "center",
                  color: qr.text_color,
                  flexDirection: "column",
                }}
                dangerouslySetInnerHTML={{
                  __html: qr.info.data.page_description,
                }}
              />
            )}
            {qr.info.data.website && (
              <a
                href={qr.info.website}
                style={{
                  display: "flex",
                  fontWeight: "normal",
                  fontSize: 15,
                  alignItems: "center",
                  justifyContent: "center",
                  color: qr.text_color,
                }}
              >
                <a href={qr.info.data.website}>
                  Vieni a trovarci anche sul nostro sito
                </a>
              </a>
            )}
          </div>

          <div
            className="flex"
            style={{ flexDirection: "column", width: "100%", gap: 8 }}
          >
            {qr.info.data.ios_url &&
              (device === "unknown" || device === "iOS") && (
                <a
                  class="app-btn blu flex vert"
                  href={qr.info.data.ios_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Apple />
                  <p>
                    Available on the <br />{" "}
                    <span class="big-txt">App Store</span>
                  </p>
                </a>
              )}
            {qr.info.data.android_url &&
              (device === "unknown" || device === "Android") && (
                <a
                  class="app-btn blu flex vert"
                  href={qr.info.data.android_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Android />
                  <p>
                    Available on the <br />{" "}
                    <span class="big-txt">Play Store</span>
                  </p>
                </a>
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};
