import { Card, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import LinkIcon from "@mui/icons-material/Link";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import dayjs from "dayjs";
import moment from "moment";

export const Event = ({ qr }) => {
  // console.log(qr);

  const handleOpenInCalendar = () => {
    const url = generateCalendarUrl();
    window.open(url);
  };

  const generateCalendarUrl = () => {
    const dataStart = `${qr.info.data.date_time_start.replace(
      /[-\s:]/g,
      ""
    )}00`;
    const dataStartFormatted = `${dataStart.substring(
      0,
      8
    )}T${dataStart.substring(8, 15)}`;
    const dataEnd = `${qr.info.data.date_time_end.replace(/[-\s:]/g, "")}00`;
    const dataEndFormatted = `${dataEnd.substring(0, 8)}T${dataEnd.substring(
      8,
      15
    )}`;
    const veventData = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:123\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nDTSTART:${dataStartFormatted}\nDTEND:${dataEndFormatted}\nSUMMARY:${qr.info.data.name}\nDESCRIPTION:${qr.info.data.event_description}\nLOCATION:${qr.info.data.location}\nEND:VEVENT\nEND:VCALENDAR`;
    const encodedVeventData = encodeURIComponent(veventData);
    const url = `data:text/calendar;charset=utf-8,${encodedVeventData}`;
    console.log("", veventData);

    return url;
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: qr.pageBackgroundColor,
        height: "100%",
      }}
    >
      <Card
        style={{
          padding: 16,
          backgroundColor: qr.card_color,
          width: 247,
          marginTop: 45,
          overflow: "visible",
        }}
      >
        {" "}
        {!!qr.image_uploaded && (
          <img
            src={qr.image_uploaded}
            alt="logo"
            style={{
              width: 90,
              height: 90,
              marginLeft: "30%",
              borderRadius: "20%",
              border: `4px solid ${qr.pageBackgroundColor}`,
              zIndex: 1000,
              marginTop: -40,
            }}
          />
        )}
        <a
          style={{
            fontWeight: "bold",
            fontSize: 25,
            flex: 1,
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: qr.text_color,
          }}
        >
          {" "}
          {qr.info.data.name}
        </a>
        <a
          style={{
            fontWeight: "unset",
            fontFamily: "serif",
            fontSize: 15,
            flex: 1,
            marginBottom: 10,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            color: qr.text_color,
          }}
        >
          {qr.info.data.event_description}
        </a>
        <EventIcon style={{ marginTop: 15 }} />
        <h3
          style={{
            color: qr.text_color,
            marginTop: -25,
            marginLeft: 30,
            marginBottom: 30,
          }}
        >
          Date
        </h3>
        <Table style={{ marginTop: -52 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="left" style={{ padding: 20 }}>
                <h3 style={{ color: qr.text_color }}>Start</h3>
                <h4
                  style={{
                    fontWeight: "normal",
                    padding: 2,
                    color: qr.text_color,
                    marginTop: -10,
                  }}
                >
                  {qr.info.data.date_time_start
                    ? dayjs(qr.info.data.date_time_start).format("DD-MM-YYYY")
                    : moment().format("DD-MM-YYYY")}
                </h4>
              </TableCell>
              <TableCell align="left">
                <h3 style={{ color: qr.text_color }}> Time</h3>
                <h4
                  style={{
                    fontWeight: "normal",
                    color: qr.text_color,
                    marginTop: -10,
                  }}
                >
                  {qr.info.date_time_start
                    ? dayjs(qr.info.date_time_start).format("HH:mm")
                    : moment().format("HH:mm")}
                </h4>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table style={{ marginTop: -10 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <h3 style={{ color: qr.text_color }}> End</h3>
                <h4
                  style={{
                    fontWeight: "normal",
                    padding: 2,
                    color: qr.text_color,
                    marginTop: -10,
                  }}
                >
                  {qr.info.data.date_time_end
                    ? dayjs(qr.info.data.date_time_end).format("DD-MM-YYYY")
                    : moment().format("DD-MM-YYYY")}
                </h4>
              </TableCell>
              <TableCell align="left">
                <h3 style={{ color: qr.text_color }}> Time</h3>
                <h4
                  style={{
                    fontWeight: "normal",
                    color: qr.text_color,
                    marginTop: -10,
                  }}
                >
                  {qr.info.data.date_time_end
                    ? dayjs(qr.info.data.date_time_end).format("HH:mm")
                    : moment().format("HH:mm")}
                </h4>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <LocationOnIcon style={{ marginTop: 15 }} />
        <h3
          style={{
            color: qr.text_color,
            marginTop: -25,
            marginLeft: 30,
            marginBottom: -15,
          }}
        >
          Location
        </h3>
        <h5
          style={{
            fontWeight: "unset",
            flex: 1,
            marginBottom: 15,
            display: "flex",
            marginTop: 25,
            marginLeft: 15,
            // textAlign: 'center',
            // alignItems: "center",
            // justifyContent: "center",
            color: qr.text_color,
          }}
        >
          {qr.info.data.location}
        </h5>
        <LinkIcon />
        <h3
          style={{
            color: qr.text_color,
            marginTop: -28,
            marginLeft: 30,
          }}
        >
          Website
        </h3>
        <h5
          style={{
            fontWeight: "unset",
            flex: 1,
            marginTop: -10,
            marginBottom: 8,
            marginLeft: 15,
            display: "flex",
            color: qr.text_color,
          }}
        >
          {qr.info.data.event_url}
        </h5>
      </Card>
      <div style={{ marginTop: 10 }}>
        <Button variant="contained" onClick={handleOpenInCalendar}>
          Open in calendar
        </Button>
      </div>
    </div>
  );
};
