import axios from "axios";

const TOKEN = "788751d511a81b";

export const getGeolocation = async () => {
  try {
    const res = await axios({
      url: `https://ipinfo.io/?token=${TOKEN}`,
      method: "GET",
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
