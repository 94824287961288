import { Card, Button } from "@mui/material";

export const Vcard = ({ qr }) => {
  console.log("QR: ", qr);

  const handleOpenInContact = () => {
    const url = generateContactsUrl();
    window.open(url);
  };

  const generateContactsUrl = () => {
    const vcardData = `BEGIN:VCARD\nVERSION:2.1\nN;CHARSET=UTF-8:${
      qr.info.data.lastname
    };${qr.info.data.firstname}\nFN;CHARSET=UTF-8:${qr.info.data.firstname};${
      qr.info.data.lastname
    }\nTEL;CELL:${qr.info.data.vcard_mobile_number
      ?.split(" ")
      .join("")}\nTEL;HOME;VOICE:${qr.info.data.vcard_phone_number
      ?.split(" ")
      .join("")}\nORG;CHARSET=UTF-8:${
      qr.info.data.company
    }\nTITLE;CHARSET=UTF-8:${qr.info.data.job_title}\nTEL;WORK;FAX:${
      qr.info.data.fax
    }\nADR;CHARSET=UTF-8;WORK;PREF:;;${qr.info.data.address};${
      qr.info.data.city
    };${qr.info.data.postcode};${qr.info.data.country}\nEMAIL:${
      qr.info.data.vcard_email
    }\nURL:${qr.info.data.website}\nEND:VCARD`;

    const encodedVcardData = encodeURIComponent(vcardData);
    const url = `data:text/vcard;charset=utf-8,${encodedVcardData}`;

    return url;
  };

  let jobTitle = qr?.info?.data?.job_title.split(" ");
  let formattedJobTitle =
    jobTitle[0].charAt(0).toUpperCase() + jobTitle[0].slice(1);
  formattedJobTitle = formattedJobTitle + " " + jobTitle[1];

  console.log("JOB:", formattedJobTitle);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: qr.pageBackgroundColor,
        height: "100%",
      }}
    >
      <Card
        style={{
          padding: 25,
          backgroundColor: qr.card_color,
          width: 247,
          marginTop: 35,
          overflow: "visible",
          minHeight: 500,
        }}
      >
        {!!qr.image_uploaded && (
          <img
            src={qr.image_uploaded}
            alt="avatar"
            style={{
              // position: "absolute",
              width: 100,
              borderRadius: "50%",
              border: `4px solid ${qr.pageBackgroundColor}`,
              zIndex: 1000,
              marginLeft: 70,
              marginTop: -50,
            }}
          />
        )}

        <a
          style={{
            fontWeight: "bold",
            fontSize: 25,
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: qr.text_color,
            marginTop: 35,
          }}
        >
          {qr?.info?.data?.lastname} {qr?.info?.data?.firstname}
        </a>
        <div
          style={{
            fontSize: 15,
            marginBottom: 30,
            marginTop: 10,
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: qr.text_color,
          }}
        >
          {formattedJobTitle}
        </div>
        <div
          style={{
            fontSize: 15,
            color: qr.text_color,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              color: qr.text_color,
            }}
          >
            Phone:
          </a>{" "}
          +39 {qr?.info?.data?.vcard_phone_number}
        </div>
        <div
          style={{
            fontSize: 15,
            color: qr.text_color,
            marginTop: 10,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              color: qr.text_color,
              marginTop: 10,
            }}
          >
            Mobile:
          </a>{" "}
          +39 {qr?.info?.data?.vcard_mobile_number}
        </div>
        <div
          style={{
            fontSize: 15,
            color: qr.text_color,
            marginTop: 10,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              color: qr.text_color,
              marginTop: 10,
            }}
          >
            Email:
          </a>{" "}
          {qr?.info?.data?.vcard_email}
        </div>
        <div
          style={{
            fontSize: 15,
            color: qr.text_color,
            marginTop: 10,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              color: qr.text_color,
              marginTop: 10,
            }}
          >
            WebSite:{" "}
          </a>
          {qr?.info?.data?.website}
        </div>
        <div
          style={{
            fontSize: 15,
            color: qr.text_color,
            marginTop: 10,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              color: qr.text_color,
              marginTop: 10,
            }}
          >
            Company:{" "}
          </a>
          {qr?.info?.data?.company}
        </div>

        <div
          style={{
            fontSize: 15,
            color: qr.text_color,
            marginTop: 10,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              color: qr.text_color,
              marginTop: 10,
            }}
          >
            Address:{" "}
          </a>
          {qr?.info?.data?.address} ({qr?.info?.data?.city}){" "}
          {qr?.info?.data?.postcode} {qr?.info?.data?.country}
        </div>
        <div
          style={{
            fontSize: 15,
            color: qr.text_color,
            marginTop: 10,
          }}
        >
          <a
            style={{
              fontWeight: "bold",
              color: qr.text_color,
              marginTop: 10,
            }}
          >
            Fax:{" "}
          </a>
          {qr?.info?.data?.fax}
        </div>
      </Card>
      <div style={{ marginTop: 10 }}>
        <Button variant="contained" onClick={handleOpenInContact}>
          {" "}
          Open in contacts
        </Button>
      </div>
    </div>
  );
};
